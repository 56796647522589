import { SafariSwiper } from "./meta-settings.js";
import { Swiper } from "https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.esm.browser.min.js";

// Titles animation
const allTitles = [...document.getElementsByClassName(`title`)];
if (allTitles.length > 0) {
    const iOSVersion = iOSversion();
    const safariVersion = String(navigator.sayswho).split(" ");
    let flag = false;

    // return all under Safari 12 Desktop
    if (safariVersion[0] === "Safari" && Number(safariVersion[1]) < 12) {
        flag = true;
    }

    // return all under Safari 12 iOS
    if (
        iOSVersion &&
        (iOSVersion[0] < 12 || (iOSVersion[0] === 12 && iOSVersion[1] < 2))
    ) {
        flag = true;
    }

    if (!flag) {
        const observerTitles = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("show");
                    }
                });
            },
            { threshold: 0.3 }
        );

        for (let i = 0; i < allTitles.length; i++) {
            // Show titles
            observerTitles.observe(allTitles[i]);

            const text = allTitles[i].innerText;

            const wordArr = allTitles[i].textContent.trim().split(" ");
            allTitles[i].innerHTML = "";

            const spanArr = [];

            wordArr.forEach((elem) => {
                const span = document.createElement("span");
                span.innerHTML = `${elem}`;
                spanArr.push(span);
            });

            let accum = 0;

            for (let j = 0; j < spanArr.length; j++) {
                const spanFather = document.createElement("span");
                const letterArr = spanArr[j].innerText.trim().split("");

                if (j > 0) accum += spanArr[j - 1].innerText.length;

                for (let k = 0; k < letterArr.length; k++) {
                    const span = document.createElement("span");

                    span.innerHTML = letterArr[k];

                    span.style.cssText = `transition-delay: ${
                        (accum + k) * 100
                    }ms`;

                    spanFather.append(span);
                }

                const span = document.createElement("span");
                span.innerHTML = "&nbsp;";
                spanFather.append(span);
                allTitles[i].append(spanFather);
            }
        }
    }
}

const header = document.getElementsByClassName(`header`)[0];

// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};
document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 200) {
        header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
        header.classList.remove("scrolled");
    }
});

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".header-mobile").length;

// toggle menu handler
function menuToggle() {
    $(".burger").toggleClass("active");
    $(".mobile-header").toggleClass("active");
    $(".header").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    scrollLock.getScrollState()
        ? scrollLock.disablePageScroll(document.querySelector(".mobile-navbar"))
        : scrollLock.enablePageScroll();
}

//menu update function
function updateMenu() {
    isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint ||
        $(".mobile-header").length;
    if (!isMobileMenuEnable) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".burger").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Input Focus
const inputs = document.querySelectorAll(".input-item input");
const textareas = document.querySelectorAll(".input-item textarea");

function focusHandler(items) {
    items.forEach((item) => {
        item.addEventListener("focus", (e) => {
            e.target.parentElement.classList.add("focus");
        });

        item.addEventListener("blur", (e) => {
            e.target.parentElement.classList.remove("focus");
        });
    });
}

if (inputs.length > 0) {
    focusHandler(inputs);
}

if (textareas.length > 0) {
    focusHandler(textareas);
}

// Cards hover
VanillaTilt.init(document.querySelectorAll(".tilt"), {
    perspective: 1000,
    transition: true,
});

// Tesla style menu
const navbar = document.getElementsByClassName(`navbar`)[0];
if (navbar) {
    const navbarElements = navbar.querySelectorAll(`a`);
    const spanElement = navbar.querySelector(`span`);
    const activeMenuElement = navbar.querySelector(`a.active`);

    function backgroundMenuPositionFunc(targetElement, flagMouseEnter) {
        const navbarPosition = navbar.getBoundingClientRect();
        const elementPosition = targetElement.getBoundingClientRect();

        let spanPositionLeftStart = elementPosition.left - navbarPosition.left;
        let spanWidthStart = elementPosition.width;

        if (flagMouseEnter) {
            spanElement.style.setProperty(
                "--span-transition",
                `0.5s cubic-bezier(0.75, 0, 0, 1)`
            );
        } else {
            spanElement.style.setProperty(
                "--span-transition",
                `opacity 0.5s ease, visibility 0s 0s`
            );
        }
        spanElement.style.setProperty("--width-span", `${spanWidthStart}px`);
        spanElement.style.setProperty(
            "--left-position-span",
            `${spanPositionLeftStart}px`
        );
    }

    if (activeMenuElement) {
        backgroundMenuPositionFunc(activeMenuElement, true);
        spanElement.classList.add("active");

        navbarElements.forEach((elem) => {
            elem.addEventListener("mouseenter", function (e) {
                backgroundMenuPositionFunc(e.target, true);
            });

            navbar.addEventListener("mouseleave", function (e) {
                backgroundMenuPositionFunc(activeMenuElement, true);
            });
        });
    } else {
        let flagMouseEnter = false;

        navbarElements.forEach((elem) => {
            elem.addEventListener("mouseenter", function (e) {
                backgroundMenuPositionFunc(e.target, flagMouseEnter);
                spanElement.classList.add("active");

                flagMouseEnter = true;
            });
        });

        navbar.addEventListener("mouseleave", function (e) {
            spanElement.classList.remove("active");
            flagMouseEnter = false;
            spanElement.style.setProperty(
                "--span-transition",
                `opacity 0.5s ease, visibility 0s 0.5s`
            );
        });
    }
}

// Custom Select
const select2Array = Array.from(
    document.getElementsByClassName("select2-item")
);
if (select2Array.length > 0) {
    select2Array.forEach((elem) => {
        $(elem).select2({
            allowClear: true,
            minimumResultsForSearch: Infinity,
            width: "100%",
        });
    });
}

const sortSelect = document.querySelector(".select2-sort");
if (sortSelect) {
    $(sortSelect).select2({
        allowClear: true,
        minimumResultsForSearch: Infinity,
        dropdownParent: $(".sorting"),
    });
}

const formFitlerJobs = document.getElementById("form-filter");
if (formFitlerJobs) {
    // Multi Select
    let locationParents = [];

    locations.forEach((elem) => {
        if (elem.subs) {
            locationParents.push(elem.title);
        }
    });

    function limitInput(input) {
        const items = input.value.split(", ");

        return items.length > 1
            ? (input.value = `${items.length} selected`)
            : (input.value = `${items[0]}`);
    }

    function multiSelection(selectTree) {
        selectTree.onChange(() => {
            const mainInput = selectTree.elemInput;
            const inputHidden = selectTree._elemWrapper[0].nextElementSibling;

            let selectedItems = selectTree.getSelectedNames()
                ? selectTree.getSelectedNames()
                : [];

            if (selectedItems.length) {
                selectedItems = selectedItems.filter(function (el) {
                    return locationParents.indexOf(el) < 0;
                });
            }

            // Change input value
            limitInput(mainInput);

            if (selectTree.getSelectedIds()) {
                inputHidden.value = selectTree
                    .getSelectedIds()
                    .filter((elem) => elem[0] !== "#");
            } else {
                inputHidden.value = "";
            }
        });
    }

    const multiSelect = $(".multi-select");
    if (multiSelect) {
        // Select options
        const selectTree = multiSelect.comboTree({
            source: locations,
            isMultiple: true,
            cascadeSelect: true,
        });

        multiSelection(selectTree);
    }

    const multiCheckbox = $(".multi-checkbox");
    if (multiCheckbox) {
        // Select options
        const selectTree = multiCheckbox.comboTree({
            source: filteredTypes,
            isMultiple: true,
        });

        multiSelection(selectTree);
    }
}

// Choose File
const fileBtn = document.getElementById("cv-file");
if (fileBtn) {
    const formPreview = document.querySelector(".file-name");

    fileBtn.addEventListener("change", () => {
        uploadFile(fileBtn.files[0]);
    });

    function uploadFile(file) {
        const fileRead = new FileReader();
        formPreview.innerHTML = file.name;
    }
}

// Parallax effect
function parallaxEffect(elements) {
    // Magic happens here
    function parallax(e) {
        let width = window.innerWidth / 2;

        let mouseX = e.clientX;

        let position = `${10 - (mouseX - width) * 0.01}%`;

        elements.forEach((item, i) => {
            item.style.transform = `rotate(11deg) translateY(${position})`;
            // First page
            if (i % 2 === 0) {
                item.style.transform = `rotate(11deg) translateX(-${position})`;
            } else {
                item.style.transform = `rotate(-11deg) rotateY(-180deg) translateY(${position})`;
            }

            if (item.classList.contains("left")) {
                item.style.transform = `rotate(-11deg) translateX(-${position})`;
            }

            if (item.classList.contains("right")) {
                item.style.transform = `rotate(11deg) translateX(-${position})`;
            }
        });
    }

    document.addEventListener("mousemove", parallax);

    if (window.matchMedia("(max-width: 551px)").matches) {
        document.removeEventListener("mousemove", parallax);
    }
}
const parallaxItems = document.querySelectorAll(".parallax");
parallaxEffect(parallaxItems);

// Jobs Swiper
const jobSection = document.getElementsByClassName(`featured-jobs`)[0];
if (jobSection) {
    const swiper = new Swiper(".job-swiper", {
        spaceBetween: 16,
        observer: true,
        speed: 600,
        slidesPerView: "auto",

        // Autoplay
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
    });

    SafariSwiper(swiper);
}

// Insights Swiper
const insightSection = document.querySelector(".insights-section");
if (insightSection) {
    const swiper = new Swiper(".insights-swiper", {
        spaceBetween: 16,
        observer: true,
        speed: 600,
        slidesPerView: "auto",

        // Autoplay
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
    });

    SafariSwiper(swiper);
}

// Career images
const careersSection = document.querySelector(".careers");
if (careersSection) {
    const swiper = new Swiper(".careers-img-swiper", {
        spaceBetween: 30,
        observer: true,
        speed: 600,
        slidesPerView: "auto",
        grabCursor: true,

        // Autoplay
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
    });

    SafariSwiper(swiper);
}

// Benefits
// const benefitsSection = document.querySelector(".benefits");
// if (benefitsSection) {
//   MetaSwiper(".benefits-swiper", {
//     spaceBetween: 30,
//     observer: true,
//     speed: 600,
//     slidesPerView: "auto",
//     grabCursor: true,
//     centeredSlides: true,
//
//     // Autoplay
//     autoplay: {
//       delay: 4000,
//       disableOnInteraction: false,
//       pauseOnMouseEnter: true,
//     },
//
//     // Breakpoints
//     breakpoints: {
//       768: {
//         centeredSlides: false,
//       }
//     }
//   });
// }

// Testimonials
const testimonialSection = document.querySelector(".testimonials");
if (testimonialSection) {
    const testimonialSwiper = new Swiper(".testimonials-swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        grabCursor: true,
        speed: 500,
        autoHeight: true,
        rewind: true,

        // Autoplay
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },

        // Pagination
        pagination: {
            hideOnClick: false,
            clickable: true,
            el: ".swiper-pagination",
        },

        // Navigation
        navigation: {
            nextEl: ".swiper-btn-next.testim-btn",
            prevEl: ".swiper-btn-prev.testim-btn",
        },
    });

    SafariSwiper(testimonialSwiper);

    let swiperStatus = false;

    const options = {
        threshold: 0.6,
    };

    const callbackSwiperToggleAutoplay = function (entries, observer) {
        entries.forEach((elem) => {
            if (elem.target.classList.contains("testimonials")) {
                swiperStatus = elem.isIntersecting;
            }
        });

        if (swiperStatus) {
            testimonialSwiper.autoplay.start();
            testimonialSwiper.update();
        } else {
            testimonialSwiper.autoplay.stop();
            testimonialSwiper.update();
        }
    };

    const observer = new IntersectionObserver(
        callbackSwiperToggleAutoplay,
        options
    );

    observer.observe(testimonialSection);
}

// Types of roles
const tabs = document.getElementsByClassName(`tab-item`)[0];
if (tabs) {
    $(".tab-item").beefup({
        trigger: ".tab-trigger",
        content: ".tab-content",
    });
}

function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [
            parseInt(v[1], 10),
            parseInt(v[2], 10),
            parseInt(v[3] || 0, 10),
        ];
    }
}
